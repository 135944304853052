import React, { Component, Fragment, useState } from "react";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {
  Grid,
  Paper,
  Box,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { userInfo, processLogin, logout } from "~/redux/actions/user";
import {
  keepSessionLive,
  checkSessionTimout,
  checkLoggedIn,
  removeCookie,
  clientInfo,
  userinfo,
} from "~/redux/helpers/user";
import { fetchCashInMessage } from "~/redux/helpers/cashinmessage";
import Header from "~/components/Header";
import { AlertDialog } from "~/components/Dialogs";
import MfaHeader from "~/components/Header/MfaHeader";
import LandingPage from "~/views/LandingPage";
import Payments from "~/views/Payments";
import PaymentPreference from "~/views/PaymentPreference";
import Redeem from "~/views/Redeem";
import Footer from "~/components/Footer";
import FAQs from "~/views/FAQs";
import Contact from "~/views/Contact";
import Support from "~/views/Support";
import { ErrorPage } from "~/views/Error/error";
import IdleTimer from "react-idle-timer";
import Thankyou from "~/views/Thankyou";
import MFAElan from "~/views/MFAElan";
import Redeemmessage from "~/views/Redeemmessage";
import "./App.scss";
import "typeface-roboto";
import Bankers from "./views/Bankers";
import Reports from "./views/Reports";
import Unauthorized from "./views/Unauthorized";
import SessionTimedOut from "./views/SessionTimedOut";
//import { withRouter } from 'react-router'

const getQueryVar = (key) => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === key) {
      return decodeURIComponent(pair[1]);
    }
  }
};

// const [cashInMessage, setCashInMessage] = useState("");

// const displayCashInMessage = () => {
// 	setCashInMessage(fetchCashInMessage());
// }

const AuthRoute = ({
  info,
  component: Component,
  path,
  isLoggedIn,
  cashInMessage,
  ...rest
}) => {
 
  let mfaFlag = Number(new Cookies().get("mfaToken")) === 1 ? true : false;
  if (!mfaFlag) {
    // Set path and component based on mfaFlag
    path = "/mfaElan";
    Component = "MFAElan"; // Replace 'MFA' with your actual MFA component
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          // if (!mfaFlag){
          // 	return <Redirect to='/' />;
          // 	}

          if (path === "/mfaElan") {
            return (
              <Box display="flex" justifyContent="center">
                <Grid item md={11} className="container">
                  <MfaHeader
                    aria-label="mfaHeader"
                    {...props}
                    info={info}
                    isLoggedIn
                  />
                  <Box aria-label="main" pt={15} style={{ minHeight: "76vh" }}>
                    <MFAElan {...props} />
                  </Box>
                </Grid>
              </Box>
            );
          } else {
            return (
              <Box display="flex" justifyContent="center">
                <Grid item md={11} className="container">
                  <Header
                    aria-label="Header"
                    {...props}
                    info={info}
                    isLoggedIn
                  />
                  <Box aria-label="main" pt={15} style={{ minHeight: "76vh" }}>
                    <Box className="alert-message">
                      <Grid item md={12}>
                        {cashInMessage && (
                          <div
                            dangerouslySetInnerHTML={{ __html: cashInMessage }}
                          ></div>
                        )}
                      </Grid>
                    </Box>
                    <Component {...props} />
                  </Box>
                  <Footer aria-label="Footer" {...props} isLoggedIn />
                </Grid>
              </Box>
            );
          }
        }
        // else {
        // 	return <Redirect to='/' />;
        // }
        else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 1000 * 5 * 12 * 10,
      isTimedOut: false,
      isLoading: true,
      metadata: { title: "Elan Financial Services | Banker Portal" },
      logout: false,
      cashInMessage: "",
      Flag: false,
      errorDialog: false,
	  p1:null,
	  p2:null,
	  p3:null,
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.pingSession);
    clearInterval(this.pingKeepSessionLive);
  }
  handleError = () => {
    this.setState({ errorDialog: true });
  };
  checkMfaClient() {
  //   const p1 = new Cookies().get("oeid");
  //   const p2 = new Cookies().get("portbu");
	// const { userInfo } = this.props.user.info;
  //   let p3;
  //   if (userInfo && userInfo.LOC) {
  //     p3 = userInfo.LOC;
  //   } else {
  //     p3 = new Cookies().get("locationId");
  //   } 
  //   this.handleData(p1, p2,p3);
  //   // }
  // alert("njnjnj")
  const { userInfo } = this.props.user.info;
  // if(userInfo){
    const currentP1 = new Cookies().get("oeid");
    const currentP2 = new Cookies().get("portbu");
    
	let currentP3;
    if (userInfo && userInfo.LOC) {
		currentP3 = userInfo.LOC;
    } else {
		currentP3 = new Cookies().get("locationId");
    } 
    
    if(!currentP3){
      this.setState({
        isLoading: true,
      }); 
      return false
    }
    // alert("tttt")
    if (currentP1 !== this.state.p1 || currentP2 !== this.state.p2 || currentP3 !== this.state.p3) {
      // Values have changed, call handleData
      this.setState({ p1: currentP1, p2: currentP2,p3: currentP3 }, 
       
        () => {
          // alert("qqq")
          if((this.state.p1 && this.state.p1.trim().length >1) && (this.state.p2 && this.state.p2.trim().length >1) && (this.state.p3 && this.state.p3.trim().length >1)){
        this.handleData(this.state.p1, this.state.p2, this.state.p3);}
        else {
          this.setState({
            isLoading: false,
          });
        }
      });
    }
  // }
 
    // this.setState({
    //   isLoading: false,
    // });
  }
  handleData = (p1, p2,p3) => {
  // alert("oioio")
    // const p3=userInfo && userInfo.LOC;
    clientInfo(p1, p2, p3).then((response) => {
      if (response.error) {
        //just return false in case of API though any error
        //error message
        this.setState({ isLoading: false });
        this.handleError();
        return false;
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  componentDidUpdate(prevProps){
    const { userInfo } = prevProps.user.info;
    if(!userInfo && this.props.user.info.userInfo){
      this.checkMfaClient()
    }
  }
  // componentDidUpdate(prevState) {
  // //  if (prevState.p1 !== this.state.p1 || prevState.p2 !== this.state.p2 || prevState.p3 !== this.state.p3){
  //       // After the loader is set to true, call your function
  //       this.checkMfaClient();}
      
    
	
  
  // }
  
  componentDidMount() {
    this.props.dispatch(userInfo()).then((response) => {
      if(response){
        this.checkMfaClient()
      }
     
    });
   
     
    // this.checkMfaClient();

    setTimeout(() => {
      fetchCashInMessage({ lAM: "" }).then((message) => {
        let messages =
          message && message.Data && message.Data.length > 0
            ? message.Data[0].BannerDescription
            : "";
        this.setState({
          cashInMessage: messages,
        });
      });
    }, 1000);

    //This will run in every 5 minutues to check token is valid or not
    this.pingSession = setInterval(() => {
      console.log("ping ");
      try {
        const checkSession = checkSessionTimout().then((response) => {
          console.log("check ping response", response);
          if (!response) {
            console.log("logout ping");
            this.props.history.push("/sessionOut");
            this.props.dispatch(logout());
          }
        });
      } catch (ex) {
        console.log("Exception ping");
        this.props.history.push("/sessionOut");
        this.props.dispatch(logout());
      }
    }, 270000);

    //This will update token in every 10 minutues case user is not idle, If user id idle then logout
    this.pingKeepSessionLive = setInterval(() => {
      console.log("keepSessionLive ");
      //If user idle for 10 minutues logout
      if (this.idleTimer && this.idleTimer.isIdle()) {
        console.log("keepSessionLive logout");
        this.props.history.push("/sessionOut");
        this.props.dispatch(logout());
      } else {
        try {
          const updatedSession = keepSessionLive().then((response) => {
            console.log("keepSessionLive response", response);
            if (!response) {
              console.log("logout keepSessionLive");
              this.props.history.push("/sessionOut");
              this.props.dispatch(logout());
            }
          });
        } catch (ex) {
          console.log("Exception keepSessionLive");
          this.props.history.push("/sessionOut");
          this.props.dispatch(logout());
        }
      }
    }, 600000);
  }

  _onAction(e) {
    console.log("On action");
    //console.log('last active time', Date(this.idleTimer.getLastActiveTime()));
    //console.log('time remaining', this.idleTimer.getRemainingTime()/60000);
    //console.log('time elapsed', this.idleTimer.getElapsedTime()/60000);
    // console.log('id idle', this.idleTimer.isIdle());

    checkLoggedIn().then((response) => {
      console.log("use logged in", response);

      if ((!response || this.state.logout) && this.props.user.isLoggedIn) {
        this.setState({ logout: true });
        this.props.history.push("/sessionOut");
        this.props.dispatch(logout());
        return false;
      }

      this.setState({ logout: false });
    });
  }

  _onActive(e) {
    console.log("On active");
  }

  _onIdle(e) {
    console.log("On idle");
    this.setState({ logout: true });
    //this.props.history.push("/sessionOut");
    //this.props.dispatch(logout());
  }

  render() {
    const { isLoggedIn , info} = this.props.user;
    // console.log('new Cookies().get("locationId")',new Cookies().get("locationId"))
    // let LOC = null
    // if(info && info.userInfo && info.userInfo.LOC){
    //   LOC = info.userInfo.LOC
    // }
    // console.log("user", isLoggedIn, this.props.user)
    const {
      isLoading,
      metadata,
      logout,
      cashInMessage,
      open,
      Flag,
      errorDialog,
    } = this.state;
    if (isLoading) {
      return null;
    }
    return (
      <BrowserRouter>
        <React.Fragment>
          <Helmet>
            <title>{metadata.title}</title>
            <meta name="title" content={metadata.title} />
            <meta name="description" content={metadata.description} />
            <meta name="keywords" content={metadata.keywords} />
          </Helmet>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout}
          />
          <Switch>
            <Route exact path="/error" component={ErrorPage} />
            <Route
              exact
              path="/"
              component={(props) => {
                if (isLoggedIn) {
                  // if(LOC){
                  //   return <Redirect to="/redeem" />;
                  // }
                  return <Redirect to="/mfaElan" />;
                }

                const searchString = props.location.search.substring(1);
                const queryVars = searchString.split("&");
                const status = getQueryVar("status");

                console.log("accessToken => ", getQueryVar("accessToken"));
                if (status === "success") {
                  this.props.dispatch(
                    processLogin({
                      accessToken: getQueryVar("accessToken"),
                      refreshToken: getQueryVar("refreshToken"),
                      portbu: getQueryVar("portbu"),
                      oeid: getQueryVar("oeid"),
                      am: getQueryVar("am"),
                      //portbu: 990006,
                      //oeid: 25006,
                    })
                  );
             
                  return <Redirect to="/mfaElan" />;
                } else {
                  if (logout) {
                    console.log("session out");
                    return <Redirect to="/sessionOut" />;
                  } else {
                    console.log("unauthorized");
                    return <Unauthorized />;
                  }
                }
              }}
            />

            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              exact
              path="/redeem"
              component={Redeem}
              cashInMessage={cashInMessage}
            />
            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              exact
              path="/mfaElan"
              component={MFAElan}
              cashInMessage={cashInMessage}
            />

            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              exact
              path="/payments"
              component={Payments}
              cashInMessage={cashInMessage}
            />
            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              exact
              path="/manage-account"
              component={PaymentPreference}
              cashInMessage={cashInMessage}
            />
            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              exact
              path="/faq"
              component={FAQs}
              cashInMessage={cashInMessage}
            />
            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              exact
              path="/contact"
              component={Contact}
              cashInMessage={cashInMessage}
            />
            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              path="/support"
              component={Support}
              cashInMessage={cashInMessage}
            />
            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              path="/bankers"
              component={Bankers}
              cashInMessage={cashInMessage}
            />
            <AuthRoute
              info={this.props.user.info.userInfo}
              isLoggedIn={isLoggedIn}
              path="/reports"
              component={Reports}
              cashInMessage={cashInMessage}
            />
            <Route
              path="/sessionOut"
              render={(props) => (
                <Box display="flex" justifyContent="center">
                  <Grid item md={11} className="container">
                    {/* <Header info={this.props.user.info.userInfo} {...props} isLoggedIn={false} /> */}
                    <Box pt={15} style={{ minHeight: "76vh" }}>
                      <SessionTimedOut {...props} />
                    </Box>
                    {/* <Footer {...props} isLoggedIn={false} /> */}
                  </Grid>
                </Box>
              )}
            />

            <Route
              exact
              path="/logout"
              render={(props) => (
                <Box display="flex" justifyContent="center">
                  <Grid item md={11} className="container">
                    <Header
                      aria-label="Header"
                      info={this.props.user.info.userInfo}
                      {...props}
                      isLoggedIn={false}
                    />
                    <Box pt={15} style={{ minHeight: "76vh" }}>
                      <Thankyou {...props} />
                    </Box>
                    <Footer {...props} isLoggedIn={false} />
                  </Grid>
                </Box>
              )}
            />

            <Route
              exact
              path="/mfalogout"
              render={(props) => (
                <Box display="flex" justifyContent="center">
                  <Grid item md={11} className="container">
                    <Header
                      aria-label="Header"
                      info={this.props.user.info.userInfo}
                      {...props}
                      isLoggedIn={false}
                    />
                    <Box pt={15} style={{ minHeight: "76vh" }}>
                      <Redeemmessage {...props} />
                    </Box>
                    <Footer {...props} isLoggedIn={false} />
                  </Grid>
                </Box>
              )}
            />
            {/* <Route exact path='/mfa' render={(props) => (
							<Box display="flex" justifyContent="center">
								<Grid item md={11} className="container">
									<Header aria-label="Header" info={this.props.user.info.userInfo} {...props} isLoggedIn={false} />
									<Box pt={15} style={{ minHeight: '76vh' }}>
									<MFAElan {...props} ui={false} />
									</Box>
									<Footer {...props} isLoggedIn={false} />
								</Grid>
							</Box>
						)} /> */}
          </Switch>
        </React.Fragment>
        {errorDialog && (
          <AlertDialog
            title={"OOPS!"}
            message={
              "Something went wrong, We're working on getting this fixed as soon as we can."
            }
            onConfirm={() => {
              this.setState({ errorDialog: false });
            }}
          />
        )}
      </BrowserRouter>
    );
  }
}

export default connect((state) => ({ ...state.user }))(App);
