import React, {Component, Fragment} from 'react';
import { Box, Paper, Grid, Container,TextField,Button,CircularProgress } from '@material-ui/core';
import { AlertOTPDialog, AlertDialog } from '~/components/Dialogs'
import { sendOTPLink } from "~/redux/helpers/banker";
import { verifyOTPLink, getOTPLink } from "~/redux/actions/bankers";
import "./styles.scss";
import { connect } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Cookies from 'universal-cookie';
import { clientInfo } from '~/redux/helpers/user';
class MFAElan extends Component{
    constructor(props) {
        super(props);
        this.state = {
        validate: 1,
        alertBox: false,
        attempts: true,
        textCode:"Email Me A Code",
        otperrpr: null,
        count: 0,
        errortext:"",
        errorDialog: false,
        otp: null,
        Email:null,
        isLoading:false,
        countForOtp:0,
    }}
    
    componentDidMount() {
      this.setState({
        isLoading: true})
      this.updateEmail();
      this.checkMfaClient();
    }
  
    componentDidUpdate(prevProps) {
      if (this.props.user.info !== prevProps.user.info) {
        this.updateEmail();
      }
    }
  checkMfaClient(){
    let mfaFlag = Number(new Cookies().get("mfaToken")) === 1 ? true : false;
    if(mfaFlag){
    this.props.history.push("/redeem")}
    else{
                this.setState({
                  isLoading: false})
              }
    // const p1 =  new Cookies().get("oeid");
		// const p2 = new Cookies().get("portbu");
        // this.handleData(p1, p2);
  // }
}
  // handleData=(p1,p2)=>{
  //   const { userInfo } = this.props.user.info;
  //   let p3
  //   if (userInfo && userInfo.LOC){
  //      p3=userInfo.LOC;
  //   }
  //   else{
  //      p3 = new Cookies().get("locationId"); 
  //   }
  //   // const p3=userInfo && userInfo.LOC;
	// 			clientInfo(p1,p2,p3).then((response) => {
	// 					if (response.error) {
	// 			  //just return false in case of API though any error
	// 			  //error message
	// 			  this.setState({ isLoading: false });
	// 			  this.handleError();
	// 			  return false;
	// 			} else {
					
  //         if (response.data[0].IsExcludedFromMFA===1){
  //         this.props.history.push("/redeem")}
  //         else{
  //           this.setState({
  //             isLoading: false})
  //         }

	// 			}
	
	// 		})
  //   }

    updateEmail() {
      const { userInfo } = this.props.user.info;
  
      if (userInfo) {
        this.setState({
          Email: userInfo.Email,
        });
      }
    }
    handleOTPChange = (event, position) => {
        const newValue = event.target.value.trim();
        const newOtp = newValue.replace(/[^0-9]/g, "");
        this.setState({ otp: newOtp });
      };
    handleError = () => {
        this.setState({ errorDialog: true });
      };
    handleClickOTP = () => {
        this.setState({ downloadProgress: true });
        // const { portbu, oeid, userInfo } = this.props && this.props.user && this.props.user.info;
        const {userInfo} = this.props.user.info;
        const { accessToken, portbu, oeid } = this.props.user.info;
        const { Email,countForOtp } = this.state;
    
        sendOTPLink({ portbu, oeid,Email}).then((response) => {
          if (response.error) {
            //just return false in case of API though any error
            //error message
            this.setState({ downloadProgress: false });
          //  this.handleError();  -- Commneted to hide errror popup message
            return false;
          } else {
            //error message
            this.setState({
              downloadProgress: false,
              textCode:"Resend Code",
              countForOtp:countForOtp+1,
              attempts: false,
            });
          }
        });
      };
    handleClickOTPClose=()=>{
      const cookies = new Cookies(window.document.cookie)
      this.props.history.push("/mfalogout");
      // cookies.remove('accessToken');
      //       cookies.remove('refreshToken');
      //       cookies.remove('portbu');
      //       cookies.remove('oeid');
      //       cookies.remove('am');
			// cookies.remove('locationId');
			// cookies.remove('mfaToken')
    }
      validateOTP = () => {
        let cookies = new Cookies(window.document.cookie)
        this.setState({ downloadProgress: true,alertBox:true });
        if (this.state.otp) {
          this.props.dispatch(getOTPLink()).then((response) => {
            if (response.error) {
              //just return false in case of API though any error
              //error message
              this.setState({ downloadProgress: false });
              this.handleError();
              return false;
            } else {
              if (
                !(
                  this.state.count >
                  this.props.bankers.Attempts[0].OtpRetryCount - 1
                )
              ) {
                // const { portbu, oeid, userInfo } = this.props.user.info;
                const {  portbu, oeid,Email } = this.props.user.info;
                const { otp, count } = this.state;
    
                this.props
                  .dispatch(verifyOTPLink({ portbu, oeid, otp }))
                  .then((response) => {
                    if (response.error) {
                      //just return false in case of API though any error
                      //error message
                      this.setState({ downloadProgress: false });
                      this.handleError();
                      return false;
                    } else {
                        if ( this.props.bankers &&
                            this.props.bankers.Validate &&
                            this.props.bankers.Validate[0].IsValid) {
                              cookies.set('mfaToken', "1", { path: '/' });
                        this.props.history.push("/redeem")}
                        
                        else{
                        // this.props.updateOpenState(this.props &&
                        //     this.props.bankers &&
                        //     this.props.bankers.Validate &&
                        //     this.props.bankers.Validate[0].IsValid? true:false
                        //       )
                      //error message
                      this.setState({
                        downloadProgress: false,
                        validate:
                          this.props &&
                          this.props.bankers &&
                          this.props.bankers.Validate &&
                          this.props.bankers.Validate[0].IsValid,
                        count: !(
                          this.props &&
                          this.props.bankers &&
                          this.props.bankers.Validate &&
                          this.props.bankers.Validate[0].IsValid
                        )
                          ? count + 1
                          : count,
                        alertBox:
                          this.props &&
                          this.props.bankers &&
                          this.props.bankers.Validate &&
                          this.props.bankers.Validate[0].IsValid
                            ? true
                            : false,
                            errortext:this.props &&
                             this.props.bankers &&
                               this.props.bankers.Validate &&
                               this.props.bankers.Validate[0].IsValid
                                ? ""
                            : "Incorrect code, please try again",
                            // textCode:"Resend Code"
                        // open:
                        //   this.props &&
                        //   this.props.bankers &&
                        //   this.props.bankers.Validate &&
                        //   this.props.bankers.Validate[0].IsValid
                        //     ? true
                        //     : false,
                      });
                    
                    }}
                  });
              } else {
                // this.setState({
                //   downloadProgress: false,
                // //   alertBox:true,
                //   errortext:"Incorrect code, 3 attempts failed. please try again later.",
                //   attempts: true,
                // });
                this.props.history.push("/mfalogout");
          //       cookies.remove('accessToken');
          //       cookies.remove('refreshToken');
          //       cookies.remove('portbu');
          //       cookies.remove('oeid');
          //       cookies.remove('am');
          // cookies.remove('locationId');
          // cookies.remove('mfaToken')
              }
            }
          });
        } else {
          this.setState({ otperrpr: "Verification Code Required", downloadProgress: false });
        }
      };
    render(){
        const {
            errorDialog,
            isLoading
          } = this.state;
          // const {  portbu, oeid } = this.props.user.info;
          // const {userInfo} = this.props.user.info;
          // const { Email } = userInfo;
          const { Email,countForOtp } = this.state;
          if(isLoading){
            return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
          }
          else{
        return(
          
            <>
            <AlertOTPDialog
            clickOutsideToClose={false}
            redeemDisabled={false}
            // title1="Redeem Your Reward"
            title2="Verification Required"
            validateOTP={this.validateOTP}
                attempts={this.state.attempts}
            showContinueBtn={true}
            px={10}
            py={4}
            downloadProgress={this.state.downloadProgress}
            onConfirm={() => this.handleClickRedeem()}
            onClose={() => this.handleClickOTPClose()}
            btnDisabled={true}
          >
               <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                 
                    <Fragment>
                      <Box className="redeem-message-OTP">
                        {`Verification is required prior to redeeming your reward. We’ll send a  one-time passcode to the email that’s been assigned in your Elan Client Resource Center User Profile ${Email}. Click “Email Me A Code” button and once received, enter your six-digit code below to continue. The code expires in 15 minutes.`}
                      </Box>
                      {/* {maskedNumber && maskedNumber.map(paymentMethod => */}
                      <Box className="redeem-message-radio disabled">
                        <label>
                          <Box textAlign="center">
                            <Button
                              style={{
                                color: "blue",
                                backgroundColor: "#dfe1ef",
                              }}
                              className={`otpBtn ${countForOtp > 3 ? 'disabled' : ''}`}
                              // className={"otpBtn"}
                              variant="outlined"
                              // color="primary"
                              disabled={countForOtp>3?true:false}
                              onClick={() => this.handleClickOTP()}
                            >
                                {/* Text me a Code */}
                              {this.state.textCode}
                            </Button>
                          </Box>
                          {/* <Radio color="black" value={paymentMethod.paymentMethodName} checked={paymentMethod.paymentTypeId === defaultPaymentId} id={paymentMethod.paymentTypeId} name="method" onChange={() => this.handlePaymentMethodsChange(paymentMethod.paymentTypeId)} /> */}
                          {/* {paymentMethod.paymentTypeId === 2 ? `${paymentMethod.paymentMethodName} ${paymentMethod.masked_AccountNumber}` : <img style={{ height: "22px", width: "85px", objectFit: "cover" }} src={require('~/assets/images/zelle-icon-registered.jpg')} />} */}
                        </label>
                      </Box>
                      {((this.props &&
                        this.props.bankers &&
                        this.props.bankers.Validate &&
                        this.props.bankers.Validate.length &&
                        !this.state.alertBox)|| this.state.attempts ) && (
                          <label>
                            {" "}
                            <Box className="redeem-message-alert">
                              {this.state.errortext}
                            </Box>
                          </label>
                        )}
                      {/* {this.state.attempts && (
                        <label>
                          {" "}
                          <Box className="redeem-message-alert">
                          {this.state.errortext}
                          </Box>
                        </label>
                      )} */}
                      {/* <Box className="code">
                                        {/* <label className="code"> */}
                      {/* Enter a code */}
                      {/* </label> */}
                      <Box className="redeem-message-Code">Enter Code</Box>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          fullWidth={true}
                          required
                          autoComplete="off"
                          variant="outlined"
                          helperText={this.state.otperrpr}
                          error={this.state.otperrpr}
                          // value={ssn.one || ""}
                          placeholder={"Enter code here"}
                          inputProps={{
                            minLength: 6,
                            maxLength: 6,
                          }}
                          onChange={(e) => {
                            this.handleOTPChange(e);
                          }}
                          type="password"
                        />
                         <Tooltip title={`If you do not receive your email within a few minutes, please check your SPAM or JUNK mail folders.
    Be sure to add the following email to your address book or safe sender list: ${Email}.\n
    If the code is not in your SPAM or JUNK folder, please verify that your email address is correct in your Elan Client Resource Center User Profile and then attempt to redeem your rewards again.\n
    If you update your email address, please note that this takes 1-2 business days to be reflected in our system. Please attempt to redeem your rewards again after that time.`} arrow>
      <HelpOutlineIcon style={{ marginLeft: 5, cursor: 'pointer' }} />
    </Tooltip>
                      </label>
                     
                      {/* <label> */}
                      <Box className="valid">Code is valid for 15 mins</Box>
                      {/* </label> */}

                      {/* )} */}
                    </Fragment>
                  
                </Box>
        </AlertOTPDialog> 
        	{errorDialog && <AlertDialog title={"OOPS!"} message={"Something went wrong, We're working on getting this fixed as soon as we can."} onConfirm={() => { this.setState({ errorDialog: false }) }} />} 
            </>       
        )}
    }
}
export default connect(state => (
        { ...state.user, ...state.bankers }
))(MFAElan)
// export default MFAElan;